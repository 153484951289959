<template>
  <div class="main__content">
    <AddKeyUserNotification
      v-if="!isUserHasEuKey"
      class="mt-6"
      :path="getCurrentProfilePath"
    />
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
    </div>
    <Table
      v-if="getTableOfVotingHistorySecretary.table"
      :content="getTableOfVotingHistorySecretary.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';
import AddKeyUserNotification from '@/elements/AddKeyUserNotification';

export default {
  components: { Table, AddKeyUserNotification },

  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getTableOfVotingHistorySecretary');
  },

  computed: {
    ...mapGetters([
      'getTableOfVotingHistorySecretary',
      'isUserHasEuKey',
      'getCurrentProfilePath',
    ]),
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfVotingHistorySecretary');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfVotingHistorySecretary');
    },
  },
};
</script>

<style lang="sass" scoped>
.table__header_bar.input-search__form
  @include xs
    width: 300px
</style>
